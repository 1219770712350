<template>
  <div class="content">
    <div class="content__title content__title--party">
      <h1 class="title title--big title--theme">Список членов РСМ</h1>
      <div class="search search--small">
        <v-select
          @search="onMemberSearch"
          @input="onSetMember"
          v-model="members_result"
          :reduce="member => member.number"
          :filterable="false"
          :clearable="false"
          :options="members_options"
          :get-option-label="getLabel"
          placeholder="Введите имя или номер члена РСМ"
          class="select"
        >
          <template slot="open-indicator">
            <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                 fill="none">
              <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                    stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"></path>
            </svg>
          </template>
          <template slot="no-options">Введите свой запрос для поиска</template>
          <template slot="option" slot-scope="option">
            <div class="select__item d-center">
              {{ option.full_name }} ({{ option.number }})
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            <div class="selected d-center">
              {{ option.full_name }} ({{ option.number }})
            </div>
          </template>
        </v-select>
      </div>
    </div>
    <div class="content__title content__title--party content__title--start">
      <router-link :to="{ name: 'journal-create' }" class="link">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="9" cy="9" r="9" fill="#2B93E7"/>
          <path
            d="M11.9694 9.67347H9.67347V11.9694C9.67347 12.2219 9.46684 12.4286 9.21429 12.4286C8.96173 12.4286 8.7551 12.2219 8.7551 11.9694V9.67347H6.45918C6.20663 9.67347 6 9.46684 6 9.21429C6 8.96173 6.20663 8.7551 6.45918 8.7551H8.7551V6.45918C8.7551 6.20663 8.96173 6 9.21429 6C9.46684 6 9.67347 6.20663 9.67347 6.45918V8.7551H11.9694C12.2219 8.7551 12.4286 8.96173 12.4286 9.21429C12.4286 9.46684 12.2219 9.67347 11.9694 9.67347Z"
            fill="white"/>
        </svg>
        <span>Добавить члена РСМ</span>
      </router-link>
      <button @click="onExport" type="button" class="link link--right" :class="{ 'link--readonly': download_loading }">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path d="M18 9c0 4.969-4.031 9-9 9s-9-4.031-9-9 4.031-9 9-9 9 4.031 9 9zm0 0" fill="#2b93e7"/><path d="M12.414 9.957H5.586c-.824 0-1.496.672-1.496 1.496s.672 1.496 1.496 1.496h6.828c.824 0 1.496-.672 1.496-1.496s-.672-1.496-1.496-1.496zm0 2.227H5.586a.729.729 0 010-1.457h6.828a.728.728 0 010 1.457zm0 0M9.27 4.203a.378.378 0 00-.54 0L7.195 5.738a.378.378 0 000 .54.38.38 0 00.543 0l.88-.88V9a.383.383 0 00.765 0V5.398l.879.88a.382.382 0 10.543-.54zm0 0" fill="#fff"/></svg>
        <span>Экспорт журнала</span>
        <img class="link__spin" v-if="download_loading" src="@/assets/img/file/spin.svg" alt="Spinner">
      </button>
    </div>
    <div class="content__title content__title--party">
      <div class="table-limiter">
        <div class="table-limiter__text">Количество записей на странице:</div>
        <div class="table-limiter__select">
          <dropdown class="my-dropdown-toggle"
                    :options="limitOptions"
                    :selected="limitSelected"
                    :closeOnOutsideClick="true"
                    @updateOption="updateLimiter"
          >
          </dropdown>
        </div>
      </div>
      <div class="table-limiter">
        <div class="table-limiter__text">
          Показаны записи <span>{{ returnEnd() > 0 ? returnStart() : 0 }} - {{ returnEnd() }}</span> из
          <span>{{ pagination.count }}</span>
        </div>
      </div>
    </div>
    <div class="filters filters--indent filters--between">
      <div class="filters__block">
        <input
          @change="fetchItems(1, limitSelected.name, status, region_org)"
          id="members1"
          type="radio"
          name="members"
          value=""
          v-model="status"
          checked
        >
        <label for="members1" class="filters__item">Все</label>
        <input
          @change="fetchItems(1, limitSelected.name, status, region_org)"
          id="members2"
          type="radio"
          name="members"
          value="active"
          v-model="status"
        >
        <label for="members2" class="filters__item">На учете</label>
        <input
          @change="fetchItems(1, limitSelected.name, status, region_org)"
          id="members3"
          type="radio"
          name="members"
          value="warning"
          v-model="status"
        >
        <label for="members3" class="filters__item">Требует внимания</label>
        <input
          @change="fetchItems(1, limitSelected.name, status, region_org)"
          id="members4"
          type="radio"
          name="members"
          value="disactive"
          v-model="status"
        >
        <label for="members4" class="filters__item">Снят с учета</label>
      </div>
      <div v-if="userRole === 'admin'" class="filters__search">
        <v-select
          @search="onSearch"
          @input="onSetOrganization"
          v-model="region_org"
          :reduce="org => org.id"
          :filterable="false"
          :options="region_options"
          :get-option-label="getLabel"
          placeholder="Выберите региональную организацию"
          class="select"
        >
          <template slot="open-indicator">
            <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                 fill="none">
              <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                    stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"></path>
            </svg>
          </template>
          <template slot="no-options">Введите свой запрос для поиска</template>
          <template slot="option" slot-scope="option">
            <div class="select__item d-center">
              {{ option.name }} (ID: {{ option.id }}) ({{ option.group_name }})
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            <div class="selected d-center">
              {{ option.name }} (ID: {{ option.id }}) ({{ option.group_name }})
            </div>
          </template>
        </v-select>
      </div>
    </div>
    <v-client-table
      @row-click="onRowClick"
      :data="table.items"
      :columns="table.columns"
      :options="table.options"
      ref="userListTable"
      class="table-default table-default--dynamic table-default--minw"
    >
      <div slot="firstname" slot-scope="props" class="table-default__left">
        {{ props.row.lastname }} {{ props.row.firstname }} {{ props.row.patronymic }}
      </div>
      <div slot="gender" slot-scope="props">
        {{ props.row.gender || '-' }}
      </div>
      <div slot="phone" slot-scope="props">
        {{ props.row.phone || '-' }}
      </div>
      <div slot="email" slot-scope="props">
        {{ props.row.email || '-' }}
      </div>
      <div slot="region_org_name" slot-scope="props">
        {{ props.row.local_org_name || props.row.region_org_name }}
      </div>
    </v-client-table>
    <paginate
      v-if="pagination.pages > 1"
      v-model="pagination.page"
      :page-count="pagination.pages"
      :clickHandler="clickPaginationCallback"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'pagination__item'"
      :page-link-class="'pagination__link'"
      :prev-class="'pagination__prev'"
      :next-class="'pagination__next'"
      :next-link-class="'pagination__link-next'"
      :prev-link-class="'pagination__link-prev'"
    >
    </paginate>
  </div>
</template>

<script>
import dropdown from 'vue-dropdowns'
import {debounce} from "lodash";

export default {
  name: 'JournalList',
  components: {
    dropdown,
  },
  data() {
    return {
      table: {
        items: [],
        columns: ['number', 'firstname', 'gender', 'dob', 'phone', 'date_entry', 'region_name', 'status_name'],
        options: {
          headings: {
            number: 'Номер',
            firstname: 'ФИО',
            gender: 'Пол',
            dob: 'Дата рождения',
            phone: 'Телефон',
            date_entry: 'Дата вступления',
            region_name: 'Регион',
            status_name: 'Статус',
          },
          pagination: {show: false},
          sortable: [],
          perPage: 20,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Нет подходящих записей',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback: row => `table-default__row table-default__row--${row.status}`,
        },
      },
      pagination: {
        pages: 1,
        page: 1,
        count: 0,
        prevLink: '',
        nextLink: ''
      },
      limitSelected: {name: 20},
      limitOptions: [
        {name: 20},
        {name: 40},
        {name: 60},
        {name: 80},
        {name: 100},
      ],
      status: 'active',
      region_org: '',
      region_options: [],
      members_result: '',
      members_options: [],
      download_loading: false,
    }
  },
  computed: {
    userRole() {
      return this.$store.state.userRole
    },
    userRegion() {
      return this.$store.state.organization
    },
    getCurrentDate() {
      const currentDate = new Date();
      return currentDate.toLocaleDateString('ru')
    }
  },
  mounted() {
    this.limitSelected.name = 20
    this.fetchItems(1, this.limitSelected.name, this.status, this.region_org)
  },
  methods: {
    fetchItems(page = 1, limit, status = '', region_org = '') {
      this.pagination.page = page
      if (!region_org) region_org = ''
      this.$store.dispatch('journal/GET_LIST', { page, limit, status, region_org})
        .then(response => {
          this.table.items = response.data.results
          this.pagination.pages = response.data.pages
          this.pagination.count = response.data.count
        })
        .catch(error => {
          this.$notify({
            type: 'warn',
            title: 'Внимание!',
            text: `Ошибка: ${error.message}`
          })
        })
    },
    updateLimiter(data) {
      this.limitSelected.name = data.name
      this.$refs.userListTable.setLimit(data.name)
      this.fetchItems(this.pagination.page, this.limitSelected.name, this.status, this.region_org)
    },
    clickPaginationCallback(page) {
      this.fetchItems(page, this.limitSelected.name, this.status, this.region_org)
    },
    returnStart() {
      return (this.pagination.page * this.table.items.length) - (this.table.items.length - 1)
    },
    returnEnd() {
      return this.returnStart() + this.table.items.length - 1
    },
    onRowClick(row) {
      this.$router.push({ name: 'journal-edit', params: { id: row.row.number } })
    },
    onSetMember() {
      this.$router.push({ name: 'journal-edit', params: { id: this.members_result } })
    },
    getLabel(option) {
      if (typeof option === 'object') {
        if (Object.prototype.hasOwnProperty.call(!option, this.label)) {
          return console.warn(
            `[vue-select warn]: Label key "option.${this.label}" does not` +
            ` exist in options object ${JSON.stringify(option)}.\n` +
            'https://vue-select.org/api/props.html#getoptionlabel'
          );
        }
        return option[this.label];
      }
      return option;
    },
    onSetOrganization() {
      this.fetchItems(1, this.limitSelected.name, this.status, this.region_org)
    },
    onSearch(search, loading) {
      loading(true);
      this.searchDelivery(loading, search, this)
    },
    searchDelivery: debounce((loading, search, vm) => {
      vm.$store.dispatch('regOrg/GET_QUERY', search).then(response => {
        vm.region_options = response.data
        loading(false)
      })
    }, 350),
    onMemberSearch (search, loading) {
      loading(true);
      this.searchMember(loading, search, this)
    },
    searchMember: debounce((loading, search, vm) => {
      vm.$store.dispatch('journal/GET_JOURNAL_SEARCH', search).then(response => {
        vm.members_options = response.data;
        loading(false);
      })
    }, 350),
    onExport() {
      this.download_loading = true
      this.$store.dispatch('journal/GET_EXPORT')
        .then(response => {
          this.download_loading = false
          const url = URL.createObjectURL(response.data)
          const link = document.createElement('a')
          link.href = url
          if (this.userRole === 'admin') link.setAttribute('download', `Журнал_учета_${this.getCurrentDate}.xlsx`)
          else link.setAttribute('download', `Журнал_учета_${this.getCurrentDate}_${this.userRegion}.xlsx`)
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(error => {
          error.response.data.text().then(res => {
            const errorJson = JSON.parse(res)
            for (const key in errorJson) {
              if (typeof errorJson[key] === 'string') {
                this.$notify({
                  type: 'error',
                  title: 'Внимание!',
                  text: errorJson[key]
                })
              } else {
                this.$notify({
                  type: 'error',
                  title: 'Внимание!',
                  text: errorJson[key][0]
                })
              }
            }
          })
        })
    }
  }
}
</script>
